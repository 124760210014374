import './TermsPopup.css'; // You can style your popup in this CSS file

export const TermsPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2>Terms and Conditions</h2>
        <div className="popup-content">
          <p>Welcome to Lea, by LegalCare (“Legal Chatbot”)! By accessing or using our Legal Chatbot, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use the chatbot.</p>
          
          <h3>1. Purpose of the Legal Chatbot</h3>
          <p>The Legal Chatbot is designed to provide general information and guidance on legal topics using generative AI. It is intended for informational purposes only and is not a substitute for professional legal advice.</p>
          
          <h3>2. No Attorney-Client Relationship</h3>
          <p>Your use of the Legal Chatbot does not create an attorney-client relationship between you and the chatbot service provider. For legal advice, you should consult a qualified legal professional. Should you choose to connect to a legal professional through our assistance, you will have a separate attorney-client relationship with the said legal professional, and we are not liable in any manner for the services provided by the said legal professional.</p>
          
          <h3>3. No Guarantee of Accuracy or Services</h3>
          <p>While we strive to provide accurate and timely information, the responses generated by the Legal Chatbot may not always reflect the most current legal standards or interpretations. The information provided may be incomplete or outdated, and we do not guarantee its accuracy or completeness. We make no warranty, express or implied, as to the accuracy of the answers or to the services provided by a legal professional that you may choose to connect with through our assistance.</p>
          
          <h3>4. User Responsibilities</h3>
          <p>You are responsible for your use of the Legal Chatbot and any decisions made based on the information provided. You agree not to rely solely on the chatbot for legal issues and to seek proper legal counsel when necessary.</p>
          
          <h3>5. User Conduct</h3>
          <p>You agree not to use the Legal Chatbot for any unlawful or prohibited purpose. You must not engage in any activity that could harm the service or its users, including but not limited to distributing malware, hacking, or attempting to gain unauthorized access to systems.</p>
          
          <h3>6. Privacy and Data Use</h3>
          <p>Your interactions with the Legal Chatbot may be recorded for quality assurance and research purposes. Please do not share any personal, confidential, or sensitive information while using the chatbot. Review our Privacy Policy for more details on how we handle your data.</p>
          
          <h3>7. Limitation of Liability</h3>
          <p>To the fullest extent permitted by law, the chatbot service provider shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the Legal Chatbot or reliance on any information obtained through it.</p>
          
          <h3>8. Changes to Terms</h3>
          <p>We may update these Terms and Conditions from time to time. Any changes will be effective immediately upon posting revised terms on the website. Your continued use of the Legal Chatbot constitutes acceptance of the new terms.</p>
          
          <h3>9. Governing Law</h3>
          <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of your use of our Legal Chatbot shall be first referred to mediation by the party raising the dispute. Subject to the foregoing, courts in Bangalore shall have exclusive jurisdiction for matters arising out of these Terms and Conditions.</p>
          
          <h3>10. Contact Information</h3>
          <p>If you have any questions or concerns about these Terms and Conditions, please contact us at help@legalcare.app</p>
          <p>By using the Legal Chatbot, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>
        </div>
        <button className="close-btn" onClick={() => onClose()}>Close</button>
      </div>
    </div>
  );
};