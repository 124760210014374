import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { doSignWithGoogle } from '../firebase/auth';
import { CgSpinner } from 'react-icons/cg';
import { useNavigate } from "react-router-dom";
import './Details.css';

export const Details = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const googleLogin = async (e) => {
    if(name === '' || phone.length > 10 || phone.length <10) {
      toast.error(`Error: Invalid Details`);
      return;
    }
    e.preventDefault();
    setIsLoading(true);
    try {
      await doSignWithGoogle(name, phone);
      navigate('/chatbot');
      toast.success(
        "Successfully signed in with Google!",
        {
          position: "top-right",
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
            color: "#fff",
          },
          duration: 2000,  // Duration of the toast in milliseconds
        }
      );
      
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="details-wrapper">
      <div className="details-header">
        <h2>Hi!</h2>
        <h2>My Name is Lea and I am a Bot</h2>
        <h2>for Legal Queries</h2>

        <p>I am currently in Beta Phase, and can only handle questions related to Criminal Law, including changes between the old criminal laws and the new ones in force from 1 July 2024!</p>
      </div>
      
      <form className="details-form">
        <Toaster toastOptions={{ duration: 4000 }} />
        <section className="formFeild">
          <input 
            type="text" 
            placeholder="Enter Name" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input 
            type="number" 
            placeholder="Enter Mobile Number" 
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </section>

        <section className="formFeild2">
          <div
            style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
            onClick={googleLogin}
          >
            <img src={require("../Assets/Images/google.png")} alt="Google Sign In" width={"70%"} />
          </div>
        </section>

        {isLoading && <CgSpinner className="spinner" />}  
      </form>
    </section>
  );
};
