import React, { useState, useRef, useEffect } from "react";
import "./ChatBot.css";
import user from "../Assets/Images/user.jpg";
import logo from "../Assets/Images/logo.png";
import {
  FaCopy,
  FaLinkedin,
  FaInstagramSquare,
  FaTwitter,
} from "react-icons/fa";
import { IoInformationCircleSharp } from "react-icons/io5";
import {AiOutlineLogout} from 'react-icons/ai';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BestPractice } from "./BestPractices";
import TypesPopUp from "./TypesPopUp";
import { doSignOut } from "../firebase/auth";
import { useNavigate } from "react-router-dom";
import DisclaimerPopup from "./DisclaimerPopup";
import Message from "./Message";
import { CategoryMessage } from "./CategoryMessage";
import { TermsPopup } from "./TermsPopUps";
import { PrivacyPopUps } from "./PrivacyPopUps";
import { AboutUs } from "./AboutUs";

const ChatBot = () => {
  const [userInput, setUserInput] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const textareaRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [openPopup, setOpenPopup] = useState(true);
  const [openTypePopUp, setOpenTypePopUp] = useState(true);
  const [types, setTypes] = useState("");
  const [isLoggingOut,setIsLggingOut] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [showCategoryMessage, setShowCategoryMessage] = useState(false);
  const [isOthersOpen, setOthersIsOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const apiKey = process.env.REACT_APP_API_KEY;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    // Update textarea and container heights based on content
    if (textareaRef.current) {
      setTextareaHeight(`${textareaRef.current.scrollHeight}px`);
    }
  }, [userInput]);

  const makeLowerCase = () => {
    return types.replace(/\s+/g, '').toLowerCase();
  }

  const fetchAnswer = async (question) => {
    const token = localStorage.getItem('firebaseToken'); // Get the token from localStorage
    try {
      console.log(question);
      const response = await axios.post(
        `${types === "" ? `${baseUrl}/answer` : `${baseUrl}/answer/${makeLowerCase(types)}`} `,
        {
          question: question,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token || apiKey}`, // Use token if available, otherwise fallback to apiKey
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleSendClick = async () => {
    setUserInput("");
    if (userInput.trim() !== "") {
      setShowAnimation(true);
      const newUserMessage = {
        content: userInput,
        isUser: true,
      };

      setChatMessages((prevMessages) => [
        ...prevMessages,
        newUserMessage,
      ]);

      try {
        const response = await fetchAnswer(userInput);
        console.log(response);
        if (response && response.status === 200) {
          const data = response.data;
          const linkRegex = /(https?:\/\/[^\s]+)/;
          const linkMatch = data.answer.match(linkRegex);
          const shuraaLink = linkMatch ? linkMatch[0] : null;
          const mainText = linkMatch
            ? data.answer.replace(linkRegex, "")
            : data.answer;
          const newAnswerMessage = {
            content:
              mainText || "Sorry this is outside my scope of knowledge...",
            links: shuraaLink || null,
            disclaimer:
              "This answer is provided to the best of Lea's available knowledge, as per data sourced from Indian laws, and reviewed by the LegalCare team. It is meant to support your quest for legal answers, and not meant to substitute legal advice by a professional . In case you require direct assistance by a legal professional, I will try to connect you with one - please mail your name, problem, phone number and location to help@legalcare.app.",
            isUser: false,
          };
          setChatMessages((prevMessages) => [
            ...prevMessages,
            newAnswerMessage,
          ]);
        }
        setTypes(""); 
        setShowCategoryMessage(true);
        
      } catch (error) {
        await onLogout();
        console.log("Error fetching answer:", error);
      }
      setShowAnimation(false);
    }
  };

  const handleTextareaKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents the default behavior (new line)
      handleSendClick();
    }
  };

  const onTogglePopUp = () => {
    setOpenPopup((prev) => !prev);
  };

  const onToggleTypePopUp = (type) => {
    if(type === "") return setOpenTypePopUp(true);
    setTypes(type);
    setOpenTypePopUp((prev) => !prev);
  }

  const handleTypeChange = (event) => {
    setTypes(event.target.value);
  }

  const onLogout = async () => {
    setIsLggingOut(true);

    try {
      await doSignOut();
      navigate('/details'); 
    } catch (error) {
      console.error(`Sign-out error: ${error.message}`);
    } finally {
      setIsLggingOut(false);
    }
  };

  const onCloseDisclaimer = () => {
    setOpenDisclaimer(false);
  }

  const onToggleMessage = () => {
    setOpenMessage(p => !p);
  }

  const togglePopup = () => {
    setOthersIsOpen(!isOthersOpen);
  };

  return (
    <>
    {openDisclaimer && <DisclaimerPopup  onClose={onCloseDisclaimer} />}
    {isLoggingOut && (
        <div className="logout-overlay">
          <div className="logout-spinner"></div>
        </div>
      )}
      {!openDisclaimer && openPopup && <BestPractice onClose={onTogglePopUp} />}
      {!openDisclaimer && openTypePopUp && <TypesPopUp onClick={onToggleTypePopUp} />}
      {!openDisclaimer && openMessage && <Message onClose={onToggleMessage} />}
      {!openDisclaimer && isOthersOpen && (
        <div className="popup">
          <ul>
            <li style={{borderBottom: "1px solid gray", cursor: "pointer" }} onClick={() => setIsAboutUsOpen(!isAboutUsOpen)}>About Us</li>
            <li style={{borderBottom: "1px solid gray", cursor: "pointer" }} onClick={() => setIsTermsOpen(!isTermsOpen)}>Terms and Conditions</li>
            <li onClick={() => setIsPrivacyOpen(!isPrivacyOpen)} style={{borderBottom: "1px solid gray", cursor: "pointer" }}>Privacy Policy</li>
          </ul>
        </div>
      )}
      {isTermsOpen && <TermsPopup onClose={() => setIsTermsOpen(!isTermsOpen)} />}
      {isPrivacyOpen && <PrivacyPopUps onClose={() => setIsPrivacyOpen(!isPrivacyOpen)} />}
      {isAboutUsOpen && <AboutUs onClose={() => setIsAboutUsOpen(!isAboutUsOpen)} />}
      <div>
        <div className="heading">
          <div className="btnList">
            <div className="bpBtn" onClick={onTogglePopUp}>
              Best Practices
            </div>

           

           <div className="lgBtn" onClick={onLogout}>
              <AiOutlineLogout size={20} style={{ marginRight: '8px' }} />
              <span>Log Out</span>
           </div>  

            <div className="lgBtn2" onClick={togglePopup}>
              <IoInformationCircleSharp size={30} />
           </div>

            <div className="bpBtn2" onClick={onTogglePopUp} style={{marginLeft: "-10px"}}>
              <img src="https://cdn-icons-png.flaticon.com/128/3655/3655399.png" alt="b_p"  />
            </div>

            <div className="lgBtn2" onClick={onLogout}>
              <AiOutlineLogout size={25} style={{marginLeft: "-10px"}}  />
           </div>
          </div>

          
          
          <img src={logo} className="logo" />
          <select className="types" value={types} onChange={handleTypeChange}>
            <option value="">Select Category before Every Question</option>
            <option value="ipcbns">Criminal Offences or Situations (BNS | IPC)</option>
            <option value="crpcbnss">Criminal Procedures such as filing complaints, etc. (BNSS | CrPC)</option>
            <option value="ieabsa">Evidence related to Criminal procedures (BSA | IEA)</option>
          </select>

          <select className="types2" value={types} onChange={handleTypeChange}>
            <option value="" title="">Select Category</option>
            <option value="ipcbns" title="Criminal Offences or Situations (BNS | IPC)">BNS | IPC</option>
            <option value="crpcbnss" title="Criminal Procedures such as filing complaints, etc. (BNSS | CrPC)">BNSS | CrPC</option>
            <option value="ieabsa" title="Evidence related to Criminal procedures (BSA | IEA)">BSA | IEA</option>
          </select>
        </div>
        <div className="div-for-mobile-only">
          {/* <div className="others2">
            <p>About Us</p>
            <p>Terms and Conditions</p>
            <p>Privacy Policy</p>
          </div> */}
          <h1>
            <span>We Are</span> LegalCare
          </h1>
          <p>Ask any question and get accurate answers in seconds!</p>
          <div className="message-lea" onClick={onToggleMessage}>
          Message from Lea
          </div>
          
        </div>
        <div className="container-holder">
          <div className="pop-up">
            <h1>
              <span>We Are</span> <br /> LegalCare
            </h1>
            <p>Ask any question and get accurate answers in seconds!</p>
            <div className="message-lea" onClick={onToggleMessage}>
                Message from Lea
            </div>
            <div className="others">
              <p style={{cursor: "pointer"}} onClick={() => setIsAboutUsOpen(!isAboutUsOpen)}>About Us</p>
              <p onClick={() => setIsTermsOpen(!isTermsOpen)} style={{cursor: "pointer"}}>Terms and Conditions</p>
              <p onClick={() => setIsPrivacyOpen(!isPrivacyOpen)} style={{cursor: "pointer"}}>Privacy Policy</p>
            </div>
          </div>
          <div className="chat-container">
            {/* Existing chat messages */}
            {chatMessages.map((message, index) => (
              <div
                key={index}
                className={`chat ${
                  message.isUser ? "outgoing outgoing-special" : "incoming"
                } ${message.isAnimation ? "animation" : ""}`}
              >
                <div className="chat-content">
                  <div className="chat-details">
                    {typeof message.content === "string" ? (
                      <p>
                        {message.content}
                        {message.links !== null ? (
                          <a
                            href={message.links}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {message.links}
                          </a>
                        ) : null}
                        {message.disclaimer && (
                          <span>
                            <br></br>
                            <br></br>
                            {message.disclaimer}
                          </span>
                        )}
                      </p>
                    ) : (
                      <p>Oops! This is out of my scope of knowledge!</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {showAnimation && (
              <div className="chat animation">
                <div className="chat-content">
                  <div className="chat-details">
                    <div className="typing-animation">
                      <div className="typing-dot"></div>
                      <div className="typing-dot"></div>
                      <div className="typing-dot"></div>
                      <h1 className="loading-message">Our Legalbot is browsing thousands of pages to get you to the right answer...</h1>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="typing-container">
          
          <div className="typing-content">
            <div className="typing-textarea">
              <textarea
                id="chat-input"
                placeholder="Enter any question here"
                value={userInput}
                onChange={(event) => setUserInput(event.target.value)}
                onKeyDown={handleTextareaKeyDown}
                disabled={openTypePopUp || types === ''}
                required
              ></textarea>
              <span id="send-btn" onClick={handleSendClick}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-mobile">
        <p>About Us</p>
        <p>Terms and Conditions</p>
        <p>Privacy Policy</p>
      </div>
    </>
  );
};

export default ChatBot;
