import './TermsPopup.css'; // You can style your popup in this CSS file

export const PrivacyPopUps = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h3>Privacy Policy for Legal Chatbot</h3>
        <div className="popup-content">
          <p>Welcome to Lea, by LegalCare (“Legal Chatbot”)! By accessing or using our Legal Chatbot, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use the chatbot.</p>
          
          <h3>1. Information We Collect</h3>
      <ul>
        <p>Your Name, Mobile Number, Email Address</p>
        <p>User Inputs: Questions and queries you submit to the chatbot.</p>
        <p>Responses: The answers and information generated by the chatbot in response to your queries.</p>
        <p>Usage Data: Information about how you interact with the chatbot, including timestamps, session duration, and engagement metrics.</p>
      </ul>
      
      <h3>2. Use of Your Information</h3>
      <ul>
        <p>User Profile: Creating your user profile on the website.</p>
        <p>Service Improvement: To analyze questions and responses to improve the quality, accuracy, and functionality of our chatbot.</p>
        <p>Training Purposes: To train and enhance our generative AI models and algorithms.</p>
        <p>User Experience: To personalize and enhance your experience while using the chatbot.</p>
        <p>Updates: Communicating with you regarding our product features and improvements, from time to time.</p>
      </ul>
      
      <h3>3. Data Storage</h3>
      <p>We may store your questions and the chatbot’s responses for research and training purposes. This stored data helps us improve our AI systems, but we take steps to protect your privacy.</p>
      
      <h3>4. Data Protection</h3>
      <p>We implement appropriate security measures to protect the information we collect from unauthorized access, disclosure, or misuse. However, please understand that no method of transmission over the internet or electronic storage is 100% secure.</p>
      
      <h3>5. Anonymization</h3>
      <p>We may anonymize your data to remove personally identifiable information. We may use this anonymized data for research and analytics without linking it back to you as an individual.</p>
      
      <h3>6. Third-Party Services</h3>
      <p>We do not sell or rent your information to third parties. However, we may use third party software, large language models, and technology hosting services, including sharing or storage of your data. We may share aggregated or anonymized data with trusted third parties for research or service improvement purposes.</p>
      
      <h3>7. Children’s Privacy</h3>
      <p>The Legal Chatbot is not intended for use by children under the age of 14. We do not knowingly collect personal information from children under this age. If we become aware that we have collected such information, we will take steps to delete it.</p>
      
      <h3>8. Changes to This Privacy Policy</h3>
      <p>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the revised policy. Your continued use of the Legal Chatbot after changes have been made constitutes your acceptance of the new terms.</p>
      
      <h3>9. Contact Information</h3>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:help@legalcare.app">help@legalcare.app</a>.</p>
      
      <p>By using the Legal Chatbot, you acknowledge that you have read, understood, and agree to this Privacy Policy.</p>
        </div>
        <button className="close-btn" onClick={() => onClose()}>Close</button>
      </div>
    </div>
  );
};