// src/firebase/auth.js

import { auth, db } from './setup'; // Import the initialized auth and db
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

export const doSignWithGoogle = async (name, phone) => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const token = await user.getIdToken();
    console.log('Firebase Token:', token);

    // Store the token in localStorage
    localStorage.setItem('firebaseToken', token);

    const response1 = await fetch('https://application.flywork.io/user', {
      method: 'POST', 
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, body: JSON.stringify({ name, phone })
    });

    if (!response1.ok) {
      throw new Error('Failed to authenticate with server');
    }

    console.log(name + " " + phone);

    // Pass the token as a header to your API
    const response2 = await fetch('https://application.flywork.io/user', {
      method: 'GET', // Change method to GET
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }, 
    });
    
    if (!response2.ok) {
      throw new Error('Failed to authenticate with server');
    }
    
    // Parse and log the response data
    const responseData = await response2.json();
    console.log('Server Response:', responseData);

    return user;
  } catch (error) {
    console.error("Error signing in with Google: ", error);
    throw error;
  }
}

export const doSignOut = () => {
  // Remove the token from localStorage on sign out
  localStorage.removeItem('firebaseToken');
  return signOut(auth);
}
