import ChatBot from "./Components/ChatBot";
import Loader from "./Components/Loader/Loader";
import { useState, useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Details } from "./Components/Details";
import { BestPractice } from "./Components/BestPractices";

const PrivateRoute = ({ element, hasAccess }) => {
  return hasAccess ? element : <Navigate to="/details" />;
};

const DetailsPage = ({ setHasAccess }) => {
  useEffect(() => {
    setHasAccess(true);
  }, [setHasAccess]);

  return <Details />;
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setLoading(false);
      document.body.style.overflow = "auto";
    }, 2000); // Replace 2000 with the desired duration in milliseconds

    // Disable scrolling during the loader
    document.body.style.overflow = "hidden";

    // Clean up by enabling scrolling and clearing the timeout when the component unmounts or when loader is removed
    return () => {
      clearTimeout(loaderTimeout);
      document.body.style.overflow = "auto"; // Re-enable scrolling
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/details" />,
    },
    {
      path: "/details",
      element: <DetailsPage setHasAccess={setHasAccess} />,
    },
    {
      path: "/chatbot",
      element: <PrivateRoute element={<ChatBot />} hasAccess={hasAccess} />,
    },
  ]);

  return (
    <div className="App">
      {loading && <Loader loading={true} />}
      {!loading && <RouterProvider router={router} />}
    </div>
  );
};

export default App;
