import React from 'react';

const Message = ({ onClose }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <h2>Hello! My Name is Lea</h2>
        <p>
          I am a Bot for Legal Queries. I am currently in Beta Phase and can only handle questions related to Criminal Law, including changes between the old criminal laws and the new ones in force from 1 July 2024!
        </p>
        <p>
          Ask any question and get answers in seconds! However, please remember that this is meant to aid your quest for help and does not constitute legal advice. If you require legal representation, you can mail at help@legalcare.app with your requirements, including your name, phone number, and location, and we shall connect you to verified legal counsels.
        </p>
        <p>
          Please note that if you select the wrong category of law for the question asked, or if you use vernacular languages for asking questions, there are greater chances of inaccurate answers.
        </p>
        <p>
          We spend money on technology, hosting, and accessing large language models for every answer the user gets. If you want to support this initiative for free legal answers, we would love for you to contribute monetarily or with your time or expertise. For more details, please mail help@legalcare.app with the subject line: Contribution, and your name, phone number and contribution offer.
        </p>
        <button onClick={() => onClose()} style={styles.closeButton}>Close</button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    maxWidth: '500px',
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Message;
