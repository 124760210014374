import './TermsPopup.css'; // You can style your popup in this CSS file

export const AboutUs = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2>About Us</h2>
        <div className="popup-content">
          <p>
            Welcome to Lea, by Legalcare, your innovative legal assistant powered by advanced generative AI technology! Our mission is to provide accessible and reliable legal information to individuals navigating the complexities of law.
            Founded by experts in law and technology, with combined experience of over 50 years, Lea is founded with a mission to democratise access to legal information and access to justice.
            At LegalCare, we understand that legal matters can be overwhelming and confusing. That's why we developed our chatbot to answer your criminal law questions in a clear and concise manner. Whether you're looking for information about your rights, legal processes, or potential defenses, our AI-driven platform is here to help you find the answers you need.
            If you are a law student or legal practitioner, Lea can become your virtual legal assistant helping you as a ready reckoner.
            Currently, our Lea chatbot is in its Beta version and focuses exclusively on criminal laws in India. We are continuously working to expand our services and improve our AI model based on user feedback and data. While we strive to provide accurate and relevant information, please remember that we do not guarantee accuracy and our chatbot does not substitute for professional legal advice.
            In addition to answering questions, we are committed to connecting users with qualified lawyers if you require legal representation. Our goal is to bridge the gap between users and legal professionals, ensuring that you can access the support you need when it matters most.
            Thank you for choosing Lea as your trusted source for legal information. If you spot any errors or inaccuracies in the information provided, please feel free to write to us – we will endeavour to train the model better. We look forward to assisting you on your legal journey! If you have any questions or feedback, please feel free to reach out to us at help@legalcare.app
            <br /><br />
            This website is owned and managed by Namaste Legal Private Limited.
            © Copyright Namaste Legal Private Limited : No part of this website may be reproduced or copied without express written consent by the owners.
          </p>
        </div>
        <button className="close-btn" onClick={() => onClose()}>Close</button>
      </div>
    </div>
  );
};
