/* eslint-disable react/prop-types */
import React from "react";
import { HashLoader } from "react-spinners";
import "./Loader.css";

const Loader = ({ loading }) => {
  return (
    <div className={`loader ${loading ? "visible" : "hidden"}`}>
      <HashLoader color={"#00adb5"} loading={loading} size={window.outerWidth/10} />
    </div>
  );
};

export default Loader;
