import "./TypesPopUp.css";

const TypesPopUp = ({onClick}) => {
  return (
  <section className="wrapper">
  <p className="message">Welcome to LegalCare! Please select a category:</p>
  <div className="types_names">
    <span className="type" onClick={() => onClick("ipcbns")}>Criminal Offences or Situations (BNS | IPC)</span>
    <span className="type" onClick={() => onClick("crpcbnss")}>Criminal Procedures such as filing complaints, etc. (BNSS | CrPC)</span>
    <span className="type" onClick={() => onClick("ieabsa")}>Evidence related to Criminal procedures (BSA | IEA)</span>

    <span className="type2" onClick={() => onClick("ipcbns")}>BNS | IPC</span>
    <span className="type2" onClick={() => onClick("crpcbnss")}>BNSS | CrPC</span>
    <span className="type2" onClick={() => onClick("ieabsa")}>BSA | IEA</span>
  </div>
  </section>
  )
}

export default TypesPopUp