// src/firebase/setup.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-13RcBFcRwUhL7guGfS9mKNAdUYbBXxQ",
  authDomain: "legal-care-gpt.firebaseapp.com",
  projectId: "legal-care-gpt",
  storageBucket: "legal-care-gpt.appspot.com",
  messagingSenderId: "193130905321",
  appId: "1:193130905321:web:cbc8aa3d188e9eba627a78",
  measurementId: "G-V579X61Z8Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);


export { app, auth };
