import "./BestPractices.css";

export const BestPractice = ({ onClose }) => {
  return (
    <section className="card">
      <h1>These are the sample types of questions on criminal laws that you can ask: </h1>
      <div className="question-section">
        <h2>Direct Questions</h2>
        <ul>
          <li>What is Stalking and its punishment?</li>
          <li>What is meant by Sexual Harassment and its punishment?</li>
          <li>How has Section 375 changed in BNS 2023?</li>
          <li>What is the law on marital rape for women under 18?</li>
        </ul>

        <h2>Comparative Questions</h2>
        <ul>
          <li>
            What are the provisions for punishment for acid attacks in the IPC,
            and how do they differ in the BNS?
          </li>
          <li>
            What are the legal consequences of human trafficking for sexual
            exploitation under the IPC, and how has the BNS revised these laws?
          </li>
          <li>
            How does the IPC define the offence of rape, and what changes, if
            any, have been introduced in the BNS?
          </li>
          <li>
            How are stalking and voyeurism defined and punished in the IPC
            compared to the BNS?
          </li>
        </ul>

        <h2>Complex Questions</h2>
        <ul>
          <li>
            My husband drinks a lot. When he comes home after work, he beats me,
            bullies me and harasses me. What recourse can I take in this regard?
          </li>
          <li>
            How does the Bharatiya Nyaya Sanhita handle the issue of marital
            rape, and what differences are there compared to the IPC's approach
            to this offence?
          </li>
          <li>
            For questions which can involve multiple sections, you may ask: "What are the provisions" e.g.: What are the provisions related to the procedure for filing a criminal complaint?
          </li>
        </ul>
      </div>

      <button className="close" onClick={() => onClose()}>Ok</button>
    </section>
  );
};
