import React, { useState } from 'react';
import './DisclaimerPopup.css'; // Optional for styling

const DisclaimerPopup = ({onClose}) => {
  return (
    <div className="popup-overlay">
              <div className="popup-box">
                <h2>Disclaimer</h2>
                <p>
                  The Bar Council of India does not permit advertisement or solicitation by advocates in any form or manner. By accessing this website, www.ask.legalcare.app, you acknowledge and confirm that you are seeking information relating to laws of your own accord and that there has been no form of solicitation, advertisement, or inducement by us or our members. The content of this website is for informational purposes only and should not be interpreted as soliciting or advertisement. The contents of this website are the intellectual property of Namaste Legal Private Limited.
                </p>
                <p>
                  No material/information provided on this website should be construed as legal advice. Ask.legalcare.app or Namaste Legal Private Limited or its shareholders, directors, and employees, shall not be liable for consequences of any action taken by relying on the material/information provided on this website.
                  By using our website and chatbot, you agree to the Terms and Conditions and Privacy Policy.
                </p>
                <button onClick={() => onClose()} className="agree-button">I AGREE</button>
              </div>
    </div>
  );
};

export default DisclaimerPopup;
